import React, { useState, useEffect } from "react";
import SidebarChat from "./SidebarChat";
import ChatWindow from "./ChatWindow";
import img from "../../Component/img/team-01.png";
import "./ChatApp.css";
import {
  fetchDataWithRetries,
  postData,
} from "../../Component/function/FunctionApi";
import axios from "axios";
import Loader from "../../Component/loader/Loader";
import { useSignalR } from "../../context/SingleRProvider";
function CompontChatApp() {
  const { setMessages } = useSignalR();

  const [conversationId, setConversationId] = useState(null);
  const [data, setData] = useState([]);
  const [status, setStatus] = useState(null);
  const [selectedFriendId, setSelectedFriendId] = useState(null);
  const [userInChat, setUserInChat] = useState(null);

  useEffect(() => {
    if (selectedFriendId) {
      console.log(selectedFriendId);
      const one = data.find((item) => item.id === selectedFriendId);
      setUserInChat(one);
    } else {
      setUserInChat(null);
    }
  }, [selectedFriendId]);

  useEffect(() => {
    // Fetch data when component mounts
    const fetchData = async () => {
      await fetchDataWithRetries("Account/accounts", setData, setStatus);
    };
    fetchData();
  }, []);

  if (!data || data.length === 0) {
    <div className="w-full">
      <div className="flex gap-10 justify-center items-center   flex-col h-full">
        <Loader />
      </div>
    </div>;
  }

  const selectedFriend =
    data.find((friend) => friend.id === selectedFriendId) || data[0];

  const createConversation = async (id) => {
    console.log(id);
    console.log(localStorage.getItem("userId_account"));
    try {
      setSelectedFriendId(id);

      const conversation = await axios.post(
        "https://sales-system.tryasp.net/api/Chat/create-conversation",
        {
          senderId: localStorage.getItem("userId_account"),
          receiverId: id,
        }
      );
      console.log("from chat appp creating conversation", conversation);
      setMessages([]);
      setConversationId(conversation.data.conversationId);
    } catch (e) {
      console.log(e);
    }
  };
  return (
    <div className="flex gap-2" id="">
      <ChatWindow
        selectedFriend={userInChat || null}
        conversationId={conversationId}
      />
      {data && (
        <SidebarChat
          data={data}
          selectedFriendId={selectedFriendId}
          onFriendSelect={createConversation}
        />
      )}
    </div>
  );
}

export default CompontChatApp;

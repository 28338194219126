import { React, useState, useEffect } from "react";
import Sidebar from "../../../Component/Sidebar/Sidebar";
import Header from "../../../Component/Header/Header";
import ComponentItemsGroup from "./ComponentItemsGroup"
import 'react-tabs/style/react-tabs.css';
import "../Home.css"

function ItemsGroup() {

  return (
    <div className="apDiv Home" >
      <Sidebar />
      <div className="body_container container" dir="rtl">
        <div className="Header">
          <Header isSearch="false" />
        </div>
        <ComponentItemsGroup/>
      </div>
    </div>
  );
}

export default ItemsGroup;

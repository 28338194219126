import { React, useState, useEffect } from "react";
import Sidebar from "../../Component/Sidebar/Sidebar";
import Header from "../../Component/Header/Header";
import Invoices from "./Invoices"

function InvoicesPage() {

    return (
        <div className="apDiv dashBoard">
            <Sidebar />
            <div className="body_container container" dir="rtl">
                <div className="Header">
                    <Header/>
                </div>
                <div className="Bills">
                  <Invoices/>
                </div>
            </div>
        </div>
    );
}

export default InvoicesPage;

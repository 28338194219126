import { React, useState, useEffect } from "react";
import Sidebar from "../../Component/Sidebar/Sidebar";
import Header from "../../Component/Header/Header";
import Card from "../../Component/DashoardContant/Cards/Card";
import { fetchDataWithRetries } from "../../Component/function/FunctionApi";
import { Link } from "react-router-dom";
import "./Dashboard.css";

function DashBoard() {
  const [data, setData] = useState("");
  const [newInvoices, setNewInvoices] = useState([]);

  useEffect(() => {
    fetchDataWithRetries("Invoice/get-statistics", setData);
    fetchDataWithRetries("Invoice/today-new-invoices", setNewInvoices);
  }, []);
  console.log(newInvoices);
  return (
    <div className="apDiv dashBoard">
      <Sidebar />
      <div className="body_container container" dir="rtl">
        <div className="Header">
          <Header isSearch="false" title="لوحة التحكم" />
        </div>
        <Card data={data.data} />
        <div className="Bills">
          <div className="addTabel">
            <div className="table-header">
              <h2 className="table-title">فواتير اليوم</h2>
              <Link to="/add-invoice" className="new-invoice-btn">
                فاتورة جديدة
              </Link>
            </div>
          </div>

          <div className="invoice-table-container">
            <div className="scrollable-table">
              <table className="provinces-table">
                <thead>
                  <tr>
                    <th>الكود</th>
                    <th>التاريخ</th>
                    <th>الحالة</th>
                    <th>العميل</th>
                    <th>المحافظة</th>
                    <th>الملاحظة</th>
                    <th>التليفون</th>
                    <th>العنوان</th>
                    <th>الصفحة</th>
                    <th>الشحن</th>
                    <th>مستعجل</th>
                    <th>لم يتم الرد</th>
                  </tr>
                </thead>
                <tbody>
                  {newInvoices.map((invoice, index) => (
                    <tr key={index}>
                      <td>{invoice.invoiceCode || 0}</td>
                      <td>
                        {new Date(invoice.invoiceDate).toLocaleDateString(
                          "ar-EG"
                        )}
                      </td>
                      <td>{invoice.status}</td>
                      <td>{invoice.customer}</td>
                      <td>{invoice.governorateId}</td>
                      <td>{invoice.note}</td>
                      <td>{invoice.phone}</td>
                      <td>{invoice.address}</td>
                      <td>{invoice.page}</td>
                      <td>{invoice.shipping.toFixed(2)}</td>
                      <td>{invoice.isUrgent ? "نعم" : "لا"}</td>
                      <td>{invoice.notReplied ? "نعم" : "لا"}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DashBoard;

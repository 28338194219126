import { React, useState, useEffect } from "react";
import Sidebar from "../../Component/Sidebar/Sidebar";
import Header from "../../Component/Header/Header";
import TableSearch from "./TableSearch/TableSearch"
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import "./SearchInvoices.css"

function SearchInvoices() {

  return (
    <div className="apDiv searchInvoices" >
      <Sidebar />
      <div className="body_container container" dir="rtl">
        <div className="Header">
          <Header isSearch="false" title="لوحة التحكم" />
        </div>
        <Tabs className="tabs">
          <TabList>
            <Tab>بحث الفواتير</Tab>
          </TabList>
          <TabPanel>
            <TableSearch/>
          </TabPanel>
        </Tabs>
      </div>
    </div>
  );
}

export default SearchInvoices;

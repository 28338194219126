import React, { useState } from 'react';
import LanguageIcon from '@mui/icons-material/Language';
import SettingsIcon from '@mui/icons-material/Settings';
import imgEng from "../img/United.svg";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import imgUser from '../img/team-01.png';
import { getCookie } from "../../Pages/Login/Login"; 
import { useNavigate } from 'react-router-dom';
import LogOut from '../LogOut/LogOut';
import './Header.css';

const Header = (props) => {
    const navigate = useNavigate(); 
    const [open, setOpen] = useState(true); 
    const [showHomeSubMenu, setShowHomeSubMenu] = useState(false);
    const [logoutDialogOpen, setLogoutDialogOpen] = useState(false); 

    const [searchTerm, setSearchTerm] = useState('');
    const [languageMenuOpen, setLanguageMenuOpen] = useState(false);
    const [userMenuOpen, setUserMenuOpen] = useState(false);

    const handleSearch = (event) => {
        const value = event.target.value;
        setSearchTerm(value);
        props.onSearchChange && props.onSearchChange(value);
    };

    const toggleLanguageMenu = () => {
        setLanguageMenuOpen((prevState) => !prevState);
    };

    const toggleUserMenu = () => {
        setUserMenuOpen((prevState) => !prevState);
    };

    const functionLogout = () => {
        document.cookie = `${encodeURIComponent("user")}=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/`;
        navigate('/'); 
    };

    const handleLogoutClick = () => {
        setLogoutDialogOpen(true); 
    };

    const handleCloseLogoutDialog = () => {
        setLogoutDialogOpen(false); 
    };

    const handleConfirmLogout = () => {
        functionLogout(); 
        handleCloseLogoutDialog(); 
    };

    let cookieValue = JSON.parse(getCookie("user"))  || '{}'; 
        
    return (
        <div className="header">
            {/* <div className="search-container">
                <input
                    type="text"
                    id="myInput"
                    placeholder="ابحث هنا..."
                    value={searchTerm}
                    onChange={handleSearch}
                    aria-label="Search"
                />
            </div> */}

            <div className="actions">
                {/* Language Selector */}
                {/* <div className="language-selector">
                    <button className="language-button" onClick={toggleLanguageMenu} aria-haspopup="true" aria-expanded={languageMenuOpen}>
                        <img src={imgEng} alt="Language Flag" className="language-flag" />
                        <span>Eng (US)</span>
                        <ExpandMoreIcon
                            className={`chevron-icon ${languageMenuOpen ? 'open' : ''}`}
                        />
                    </button>

                    {languageMenuOpen && (
                        <ul className="dropdown-menu" role="menu">
                            <li role="menuitem">English</li>
                            <li role="menuitem">العربية</li>
                        </ul>
                    )}
                </div> */}

                {/* User Info Section */}
                {cookieValue && (

                    <div className="user-info">
                    <img src={imgUser} alt="User" className="user-image" />
                    <div className="user-details" onClick={toggleUserMenu} aria-haspopup="true" aria-expanded={userMenuOpen}>
                        <div className="userName">
                            <h4>{cookieValue.displayName}</h4>
                            <ExpandMoreIcon
                                className={`chevron-icon ${userMenuOpen ? 'open' : ''}`}
                            />
                        </div>
                        <p>{cookieValue.role}</p>
                    </div>

                    {userMenuOpen && (
                        <ul className="dropdown-menu" role="menu">
                            <li role="menuitem">الملف الشخصي</li>
                            <li role="menuitem" onClick={handleLogoutClick} >تسجيل الخروج</li>
                        </ul>
                    )}
                </div>
                )}
            </div>
            <LogOut
                open={logoutDialogOpen}
                onClose={handleCloseLogoutDialog}
                onConfirm={handleConfirmLogout}
            />
        </div>
    );
};

export default Header;

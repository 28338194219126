import { React, useState, useEffect } from "react";
import Sidebar from "../../Component/Sidebar/Sidebar";
import Header from "../../Component/Header/Header";
import AddInvoice from "./AddInvoice"

function PageAddInvoice({userData}) {


    return (
        <div className="apDiv dashBoard">
            <Sidebar />
            <div className="body_container container" dir="rtl">
                <div className="Header">
                    <Header />
                </div>
                <AddInvoice userData={userData} />
            </div>
        </div>
    );
}

export default PageAddInvoice;

import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPalette } from '@fortawesome/free-solid-svg-icons';
import Draggable from 'react-draggable'; 
import './Settings.css'; 

function ThemeSwitcherWithConfirmation() {
    const [selectedColor, setSelectedColor] = useState('#ffffff'); 
    const [themeColor, setThemeColor] = useState('#ffffff');
    const [isColorPickerVisible, setIsColorPickerVisible] = useState(false); 

    useEffect(() => {
        const savedColor = localStorage.getItem('themeColor');
        if (savedColor) {
            setThemeColor(savedColor);
            document.documentElement.style.setProperty('--main-color', savedColor);
        }
    }, []);

    const handleColorChange = (color) => {
        setSelectedColor(color); 
    };

    const handleApplyColor = () => {
        setThemeColor(selectedColor); 
        document.documentElement.style.setProperty('--main-color', selectedColor);
        localStorage.setItem('themeColor', selectedColor); 
        setIsColorPickerVisible(false); 
    };

    return (
        <Draggable> 
            <div className="theme-switcher-with-icon" style={{ cursor: 'move' }}>
                <FontAwesomeIcon
                    icon={faPalette}
                    onClick={() => setIsColorPickerVisible(!isColorPickerVisible)}
                    style={{
                        fontSize: '30px',
                        cursor: 'pointer',
                        color: themeColor, 
                        marginRight: '10px',
                    }}
                />
                
                {isColorPickerVisible && (
                    <div style={{ position: 'absolute', top: '30px', left: '0', zIndex: '1000'}}>
                        <input
                            type="color"
                            value={selectedColor}
                            onChange={(e) => handleColorChange(e.target.value)}
                            className="color-input" 
                        />
                        <br />
                        <button
                            onClick={handleApplyColor}
                            className="ok-button"
                        >
                            OK
                        </button>
                    </div>
                )}
            </div>
        </Draggable>
    );
}

export default ThemeSwitcherWithConfirmation;

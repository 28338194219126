import { React, useState, useEffect } from "react";
import Sidebar from "../../../Component/Sidebar/Sidebar";
import Header from "../../../Component/Header/Header";
import ComponentGover from "./ComponentGover"
import 'react-tabs/style/react-tabs.css';
import "../Home.css"


function Governorates() { 

  return (
    <div className="apDiv Home" >
      <Sidebar />
      <div className="body_container container" dir="rtl">
        <div className="Header">
          <Header/>
        </div>
        <ComponentGover/>
      </div>
    </div>
  );
}

export default Governorates;

import React, { useState, useEffect } from "react";
import { putData } from "../../Component/function/FunctionApi";
import { fetchDataWithRetries } from "../../Component/function/FunctionApi";
import "./Invoices.css";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";
import { Button } from "react-bootstrap";

const Invoices = () => {
  const [invoicesData, setInvoicesData] = useState({ data: [] });
  const [filteredInvoices, setFilteredInvoices] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 5;

  const [openDialog, setOpenDialog] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogMessage, setDialogMessage] = useState("");
  const [dialogType, setDialogType] = useState("");
  const [selectedInvoice, setSelectedInvoice] = useState(null);
  const fetchInvoices = async () => {
    const result = await fetchDataWithRetries(
      "Invoice/get-invoice-pagination",
      setInvoicesData
    );
    if (result && result.data) {
      setInvoicesData(result);
      setFilteredInvoices(result.data);
    }
  };
  const handleOpenDialog = (invoice) => {
    setSelectedInvoice(invoice);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleSaveChanges = async () => {
    try {
      console.log("Updated Invoice:", selectedInvoice);
      const response = await putData(
        `Invoice/${selectedInvoice.id}`,
        selectedInvoice
      );

      if (response.status === 200) {
        setDialogMessage("تم تحديث الفاتورة بنجاح!");
        setDialogType("success");
      } else {
        setDialogMessage("فشل في تحديث الفاتورة. حاول مرة أخرى.");
        setDialogType("error");
      }
      setDialogOpen(true);
      setOpenDialog(false); // Open dialog
    } catch (error) {
      setOpenDialog(false);
      console.error("Error updating invoice:", error);
      setDialogMessage("حدث خطأ. حاول مرة أخرى.");
      setDialogType("error");
      setDialogOpen(true); // Open dialog
    }
  };

  const handleClose = () => {
    fetchInvoices();
    setDialogOpen(false); // Close dialog
  };
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setSelectedInvoice((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  const [filters, setFilters] = useState({
    code: "",
    date: "",
    client: "",
    phone: "",
    address: "",
    user: "",
    representative: "",
    status: "",
    quantity: "",
    governorate: "",
  });

  // Fetch invoices data
  useEffect(() => {
    fetchInvoices();
  }, []);

  // Handle filter changes
  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    if (name === "code") {
      setFilters({ ...filters, [name]: value });
    }
    setFilters({ ...filters, [name]: value });
  };

  // Apply filters on invoices data
  useEffect(() => {
    if (invoicesData.data) {
      // Check if invoicesData.data exists
      const filtered = invoicesData.data.filter((invoice) => {
        return (
          (filters.code === "" ||
            invoice.invoiceCode.toString().includes(filters.code)) &&
          (filters.date === "" || invoice.invoiceDate.includes(filters.date)) &&
          (filters.client === "" ||
            invoice.customer.includes(filters.client)) &&
          (filters.phone === "" || invoice.phone.includes(filters.phone)) &&
          (filters.address === "" ||
            invoice.address.includes(filters.address)) &&
          // (filters.representative === '' || invoice.representative.fullName.includes(filters.representative)) &&
          (filters.status === "" || invoice.status.includes(filters.status)) &&
          (filters.governorate === "" ||
            invoice.governorate.name.includes(filters.governorate))
        );
      });
      setFilteredInvoices(filtered);
    }
  }, [filters, invoicesData]);
  const handleClearSearch = () => {
    setFilters({
      code: "",
      date: "",
      client: "",
      phone: "",
      address: "",
      user: "",
      representative: "",
      status: "",
      quantity: "",
      governorate: "",
    });
  };
  // Pagination logic
  const indexOfLastInvoice = currentPage * itemsPerPage;
  const indexOfFirstInvoice = indexOfLastInvoice - itemsPerPage;
  const currentInvoices = filteredInvoices.slice(
    indexOfFirstInvoice,
    indexOfLastInvoice
  );
  const totalPages = Math.ceil(filteredInvoices.length / itemsPerPage);

  return (
    <div className="invoices-container">
      <h2>فواتير</h2>
      <hr />
      <div className="containerInput">
        <div className="w-[100%]  flex items-center justify-center">
          <button
            className="px-4 my-3 py-2 bg-red-500 text-white font-semibold rounded-lg hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-opacity-50"
            onClick={handleClearSearch} // Clear inputs on button click
          >
            مسح
          </button>
        </div>
        <div className="input-group">
          <label>
            الكود
            <input
              type="text"
              name="code"
              value={filters.code}
              onChange={handleFilterChange}
              placeholder="الكود"
            />
          </label>
          <label>
            التاريخ
            <input
              type="date"
              name="date"
              value={filters.date}
              onChange={handleFilterChange}
            />
          </label>
          <label>
            العميل
            <input
              type="text"
              name="client"
              value={filters.client}
              onChange={handleFilterChange}
              placeholder="العميل"
            />
          </label>
          <label>
            التليفون
            <input
              type="text"
              name="phone"
              value={filters.phone}
              onChange={handleFilterChange}
              placeholder="التليفون"
            />
          </label>
          <label>
            العنوان
            <input
              type="text"
              name="address"
              value={filters.address}
              onChange={handleFilterChange}
              placeholder="العنوان"
            />
          </label>
        </div>
        <div className="input-group">
          <label>
            المستخدم
            <input
              type="text"
              name="user"
              value={filters.user}
              onChange={handleFilterChange}
              placeholder="المستخدم"
            />
          </label>
          <label>
            المندوب
            <input
              type="text"
              name="representative"
              value={filters.representative}
              onChange={handleFilterChange}
              placeholder="المندوب"
            />
          </label>
          <label>
            الحالة
            <input
              type="text"
              name="status"
              value={filters.status}
              onChange={handleFilterChange}
              placeholder="الحالة"
            />
          </label>
          <label>
            الكمية
            <input
              type="number"
              name="quantity"
              value={filters.quantity}
              onChange={handleFilterChange}
              placeholder="الكميه"
            />
          </label>
          <label>
            المحافظة
            <input
              type="text"
              name="governorate"
              value={filters.governorate}
              onChange={handleFilterChange}
              placeholder="المحافظة"
            />
          </label>
        </div>
      </div>
      <div className="table-container">
        <div className="scrollable-table">
          <table className="invoices-table">
            <thead>
              <tr>
                <th>الكود</th>
                <th>التاريخ</th>
                <th>العميل</th>
                <th>التليفون</th>
                <th>العنوان</th>
                <th>المحافظة</th>
                <th>السكرتيرة</th>
                <th>عدد الأصناف</th>
                <th>تاريخ اليومية</th>
                <th>المندوب</th>
                <th>الحالة</th>
                {/* <th></th> */}
              </tr>
            </thead>
            <tbody>
              {currentInvoices.map((invoice) => (
                <tr
                  className="cursor-pointer"
                  key={invoice.id}
                  style={{ backgroundColor: "white" }}
                  onClick={() => handleOpenDialog(invoice)}
                >
                  <td>{invoice.invoiceCode}</td>
                  <td>{invoice.invoiceDate.split("T")[0]}</td>
                  <td>{invoice.customer}</td>
                  <td>{invoice.phone}</td>
                  <td>{invoice.address}</td>
                  <td>{invoice.governorate.name}</td>
                  <td>{invoice.representative?.fullName}</td>
                  <td>{invoice.invoiceCategories.length}</td>
                  <td>{invoice.dateCreated.split("T")[0]}</td>
                  <td>{invoice.userName}</td>
                  <td>{invoice.status}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        <Dialog open={openDialog} onClose={handleCloseDialog}>
          <DialogContent>
            {selectedInvoice && (
              <form className="rtl">
                {/* Row for invoice code and invoice date */}
                <div className="mb-6 grid grid-cols-1 md:grid-cols-2 gap-4">
                  <div>
                    <label className="block text-sm font-medium mb-2">
                      كود الفاتورة
                    </label>
                    <input
                      type="text"
                      name="invoiceCode"
                      value={selectedInvoice.invoiceCode}
                      onChange={handleInputChange}
                      className="w-full px-3 py-2 border border-gray-300 rounded-md"
                    />
                  </div>
                  <div>
                    <label className="block text-sm font-medium mb-2">
                      تاريخ الفاتورة
                    </label>
                    <input
                      type="date"
                      name="invoiceDate"
                      value={selectedInvoice.invoiceDate.split("T")[0]}
                      onChange={handleInputChange}
                      className="w-full px-3 py-2 border border-gray-300 rounded-md"
                    />
                  </div>
                </div>

                {/* Row for customer */}
                <div className="mb-6">
                  <label className="block text-sm font-medium mb-2">
                    العميل
                  </label>
                  <input
                    type="text"
                    name="customer"
                    value={selectedInvoice.customer}
                    onChange={handleInputChange}
                    className="w-full px-3 py-2 border border-gray-300 rounded-md"
                  />
                </div>

                {/* Row for phone and address */}
                <div className="mb-6 grid grid-cols-1 md:grid-cols-2 gap-4">
                  <div>
                    <label className="block text-sm font-medium mb-2">
                      الهاتف
                    </label>
                    <input
                      type="text"
                      name="phone"
                      value={selectedInvoice.phone}
                      onChange={handleInputChange}
                      className="w-full px-3 py-2 border border-gray-300 rounded-md"
                    />
                  </div>
                  <div>
                    <label className="block text-sm font-medium mb-2">
                      العنوان
                    </label>
                    <input
                      type="text"
                      name="address"
                      value={selectedInvoice.address}
                      onChange={handleInputChange}
                      className="w-full px-3 py-2 border border-gray-300 rounded-md"
                    />
                  </div>
                </div>

                {/* Row for governorate and representative */}
                <div className="mb-6 grid grid-cols-1 md:grid-cols-2 gap-4">
                  <div>
                    <label className="block text-sm font-medium mb-2">
                      المحافظة
                    </label>
                    <input
                      type="text"
                      name="governorate"
                      value={selectedInvoice.governorate.name}
                      onChange={handleInputChange}
                      className="w-full px-3 py-2 border border-gray-300 rounded-md"
                    />
                  </div>
                  <div>
                    <label className="block text-sm font-medium mb-2">
                      المندوب
                    </label>
                    <input
                      type="text"
                      name="representative"
                      value={selectedInvoice.representative?.fullName}
                      onChange={handleInputChange}
                      className="w-full px-3 py-2 border border-gray-300 rounded-md"
                    />
                  </div>
                </div>

                {/* Row for date created */}
                <div className="mb-6">
                  <label className="block text-sm font-medium mb-2">
                    التاريخ الإنشاء
                  </label>
                  <input
                    type="date"
                    name="dateCreated"
                    value={selectedInvoice.dateCreated.split("T")[0]}
                    onChange={handleInputChange}
                    className="w-full px-3 py-2 border border-gray-300 rounded-md"
                  />
                </div>

                {/* Row for user name and status */}
                <div className="mb-6 grid grid-cols-1 md:grid-cols-2 gap-4">
                  <div>
                    <label className="block text-sm font-medium mb-2">
                      اسم المستخدم
                    </label>
                    <input
                      type="text"
                      name="userName"
                      value={selectedInvoice.userName}
                      onChange={handleInputChange}
                      className="w-full px-3 py-2 border border-gray-300 rounded-md"
                    />
                  </div>
                  <div>
                    <label className="block text-sm font-medium mb-2">
                      الحالة
                    </label>
                    <input
                      type="text"
                      name="status"
                      value={selectedInvoice.status}
                      onChange={handleInputChange}
                      className="w-full px-3 py-2 border border-gray-300 rounded-md"
                    />
                  </div>
                </div>
              </form>
            )}
          </DialogContent>
          <DialogActions>
            <button
              className=" bg-red-400 text-white py-2 px-4 rounded-md hover:bg-red-600 transition duration-200"
              onClick={handleCloseDialog}
              color="primary"
            >
              Cancel
            </button>
            <button
              className=" bg-green-700 text-white py-2 px-4 rounded-md hover:bg-green-600 transition duration-200"
              onClick={handleSaveChanges}
              color="primary"
            >
              Save
            </button>
          </DialogActions>
        </Dialog>
        <div className="pagination">
          <span>
            صفحة {currentPage} من {totalPages}
          </span>
          <button
            onClick={() => setCurrentPage(Math.max(1, currentPage - 1))}
            disabled={currentPage === 1}
          >
            السابق
          </button>
          {Array.from({ length: totalPages }, (_, index) => (
            <button
              key={index + 1}
              onClick={() => setCurrentPage(index + 1)}
              className={currentPage === index + 1 ? "active" : ""}
            >
              {index + 1}
            </button>
          ))}
          <button
            onClick={() =>
              setCurrentPage(Math.min(totalPages, currentPage + 1))
            }
            disabled={currentPage === totalPages}
          >
            التالي
          </button>
        </div>
      </div>
      <Dialog open={dialogOpen} onClose={handleClose}>
        <DialogTitle>
          {dialogType === "success" ? "Success" : "Error"}
        </DialogTitle>
        <DialogContent>
          <h3 className="text-center p-2 ">{dialogMessage}</h3>
        </DialogContent>
        <DialogActions>
          <button
            className="bg-blue-500 text-white py-2 px-4 rounded-md"
            onClick={handleClose}
            color="primary"
          >
            Close
          </button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default Invoices;

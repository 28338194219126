import React from 'react';
import { Link } from 'react-router-dom'; 
import './Card.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTag, faFile, faSquarePollVertical } from '@fortawesome/free-solid-svg-icons';

const Cards = ({ data }) => {
    const cardsData = [
        { id: 1, color: '#f2e5ff', icon: faFile, iconColor: '#884dff', number: data?.countInvoiceDaily, title: 'عدد الفواتير اليوم', link: '/invoices/Daily' },
        { id: 2, color: '#dcfce7', icon: faTag, iconColor: '#3cd856', number: data?.countDateLate, title: 'اليوميات المتأخرة', link: '/invoices/Late' },
        { id: 3, color: '#fff4de', icon: faSquarePollVertical, iconColor: '#bf83ff', number: data?.countInoiveUrgent, title: 'المستعجل', link: '/invoices/Urgent' },
    ];

    return (
        <div className="cards">
            <h1>التحكم</h1>
            <div className='cards-container'>
                {cardsData.map((card) => (
                    <Link 
                        key={card.id} 
                        to={card.link} 
                        className="card" 
                        style={{ backgroundColor: card.color }}
                    >
                        <FontAwesomeIcon
                            icon={card.icon}
                            className="card-icon"
                            style={{ backgroundColor: card.iconColor }}
                        />
                        <div className="card-number">{card.number}</div>
                        <div className="card-title">{card.title}</div>
                    </Link>
                ))}
            </div>
        </div>
    );
};

export default Cards;

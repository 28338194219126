import React, { useState, useEffect } from 'react';
import axios from 'axios';
import "./TableGovernorates.css";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { fetchDataWithRetries } from "../../../Component/function/FunctionApi";

const GovernoratesTable = () => {
    const [totalGovernoratesData, setTotalGovernoratesData] = useState([]);
    const [newGovernoratesData, setNewGovernoratesData] = useState([]);
    const [loadingTotal, setLoadingTotal] = useState(true);
    const [loadingNew, setLoadingNew] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchTotalInvoices = async () => {
            setLoadingTotal(true);
            try {
                await fetchDataWithRetries('Invoice/get-total-invoice-with-governorate', setTotalGovernoratesData);
            } catch (error) {
                setError('Failed to fetch total invoices data.');
            } finally {
                setLoadingTotal(false);
            }
        };

        const fetchNewInvoices = async () => {
            setLoadingNew(true);
            try {
                await fetchDataWithRetries('Invoice/get-total-invoice-with-status', setNewGovernoratesData);
            } catch (error) {
                setError('Failed to fetch new invoices data.');
            } finally {
                setLoadingNew(false);
            }
        };

        fetchTotalInvoices();
        fetchNewInvoices();
    }, []);

    const renderTable = (data, loading, type) => {
        if (loading) {
            return (
                <tr>
                    <td colSpan="2">جارٍ تحميل البيانات...</td>
                </tr>
            );
        }

        if (data.length === 0) {
            return (
                <tr>
                    <td colSpan="2">لا توجد بيانات {type}.</td>
                </tr>
            );
        }

        return data.data.map((item, index) => (
            <tr key={index}>
                <td>{item.governorate}</td>
                <td>{item.total}</td>
            </tr>
        ));
    };

    return (
        <div className="governorates-container">
            {error && <div className="error-message">{error}</div>}

            <Tabs className="tabs">
                <TabList>
                    <Tab>اجمالي المحافظات اليوم</Tab>
                    <Tab>اجمالي المحافظات حاله جديدة</Tab>
                </TabList>

                {/* Total governorates today */}
                <TabPanel>
                    <table className="governorates-table">
                        <thead>
                            <tr>
                                <th>المحافظة</th>
                                <th>اجمالي الفواتير</th>
                            </tr>
                        </thead>
                        <tbody>
                            {renderTable(totalGovernoratesData, loadingTotal, "اليوم")}
                        </tbody>
                    </table>
                </TabPanel>

                <TabPanel>
                    <table className="governorates-table">
                        <thead>
                            <tr>
                                <th>المحافظة</th>
                                <th>اجمالي الفواتير</th>
                            </tr>
                        </thead>
                        <tbody>
                            {renderTable(newGovernoratesData, loadingNew, "الجديدة")}
                        </tbody>
                    </table>
                </TabPanel>
            </Tabs>
        </div>
    );
};

export default GovernoratesTable;

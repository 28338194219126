import { React, useState, useEffect } from "react";
import Sidebar from "../../Component/Sidebar/Sidebar";
import Header from "../../Component/Header/Header";
import CompontChatApp from "./CompontChatApp";

function ChatApp() {

    return (
        <div className="apDiv ChatApp">
            <Sidebar />
            <div className="body_container container" dir="rtl">
                <div className="Header">
                    <Header />
                </div>
                <div className="Bills">
                    <CompontChatApp/>
                </div>
            </div>
        </div>
    );
}

export default ChatApp;

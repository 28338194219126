import { React, useState, useEffect } from "react";
import Sidebar from "../../Component/Sidebar/Sidebar";
import Header from "../../Component/Header/Header";
import ComponentRoles from "./ComponentRoles"
import 'react-tabs/style/react-tabs.css';


function Roles() { 

  return (
    <div className="apDiv Roles" >
      <Sidebar />
      <div className="body_container container" dir="rtl">
        <div className="Header">
          <Header />
        </div>
        <ComponentRoles/>
      </div>
    </div>
  );
}

export default Roles;

import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faSave, faTrash } from '@fortawesome/free-solid-svg-icons'; // Import delete icon
import { Select, MenuItem, FormControl, InputLabel } from '@mui/material';
import { fetchDataWithRetries, postDatatoQueryParams, putData, deleteFunction } from '../../../Component/function/FunctionApi';
import './Categories.css';

const API_ENDPOINTS = {
    FETCH: 'Category/get-categories',
    CREATE: 'Category/create-category',
    UPDATE: 'Category/update-category',
    DELETE: 'Category/delete-category',
    GROUPS: 'Group/get-groups',
};

const MESSAGES = {
    FETCH_ERROR: 'فشل في تحميل البيانات. يرجى المحاولة مرة أخرى لاحقًا.',
    UPDATE_SUCCESS: 'تم تعديل الصنف بنجاح',
    CREATE_SUCCESS: 'تم إضافة الصنف بنجاح',
    DELETE_SUCCESS: 'تم حذف الصنف بنجاح',
    FORM_ERROR: 'يرجى ملء جميع الحقول بشكل صحيح.',
    DELETE_ERROR: 'فشل في حذف الصنف. يرجى المحاولة مرة أخرى.',
};

function ComponentCategories() {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const [newProvince, setNewProvince] = useState({
        id: '',
        name: '',
        deliveryDays: '',
        groupId: '',
        price: '',
        isEnabled: false
    });
    const [isEditMode, setIsEditMode] = useState(false);
    const [apiData, setApiData] = useState([]);
    const [groupNames, setGroupNames] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [isMessageModalOpen, setIsMessageModalOpen] = useState(false);
    const [messageContent, setMessageContent] = useState('');
    const [itemToDelete, setItemToDelete] = useState(null);

    useEffect(() => {
        fetchCategories();
        fetchGroups();
    }, []);

    const fetchCategories = async () => {
        try {
            setLoading(true);
            setError(null);
            await fetchDataWithRetries(API_ENDPOINTS.FETCH, setApiData);
        } catch (err) {
            setError(MESSAGES.FETCH_ERROR);
        } finally {
            setLoading(false);
        }
    };

    const fetchGroups = async () => {
        try {
            setLoading(true);
            const response = await fetchDataWithRetries(API_ENDPOINTS.GROUPS, setGroupNames);
            setGroupNames(response.data);
        } catch (err) {
            console.error("Error fetching groups", err);
        } finally {
            setLoading(false);
        }
    };

    const handleOpenModalForAdd = () => {
        setIsModalOpen(true);
        setIsEditMode(false);
        setNewProvince({ id: '', name: '', deliveryDays: '', groupId: '', price: '', isEnabled: false });
    };

    const handleOpenModalForEdit = (province) => {
        setIsModalOpen(true);
        setIsEditMode(true);
        setNewProvince(province); // Load the selected province data
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
        setNewProvince({ id: '', name: '', deliveryDays: '', groupId: '', price: '', isEnabled: false });
    };

    const handleInputChange = (e) => {
        const { name, value, type, checked } = e.target;
        if (type === 'checkbox') {
            setNewProvince((prevData) => ({ ...prevData, [name]: checked })); // Update checkbox state
        } else {
            setNewProvince((prevData) => ({ ...prevData, [name]: value })); // Update other fields
        }
    };

    const handleGroupNameChange = (event) => {
        setNewProvince((prevData) => ({ ...prevData, groupId: event.target.value }));
    };

    const validateForm = () => {
        return newProvince.name && newProvince.price >= 0 && newProvince.groupId;
    };

    const handleSave = async () => {
        if (!validateForm()) {
            setMessageContent(MESSAGES.FORM_ERROR);
            setIsMessageModalOpen(true); // Show message modal
            return;
        }

        try {
            const params = {
                Name: newProvince.name,
                Price: newProvince.price,
                IsActive: newProvince.isEnabled,
                GroupId: newProvince.groupId,
            };

            if (isEditMode) {
                await putData(`${API_ENDPOINTS.UPDATE}?id=${newProvince.id}`, params);
                setMessageContent(MESSAGES.UPDATE_SUCCESS);
            } else {
                await postDatatoQueryParams(API_ENDPOINTS.CREATE, params);
                setMessageContent(MESSAGES.CREATE_SUCCESS);
            }

            await fetchCategories();
            setIsMessageModalOpen(true); // Show success message modal
        } catch (err) {
            setMessageContent(`خطأ: ${err.message || 'فشل في حفظ البيانات. يرجى المحاولة مرة أخرى لاحقًا.'}`);
            setIsMessageModalOpen(true); // Show error message modal
        } finally {
            handleCloseModal();
        }
    };

    const handleDeleteConfirmation = (id) => {
        setItemToDelete(id); // Save item ID
        setIsDeleteModalOpen(true); // Open the delete confirmation modal
    };

    const handleDelete = async (id) => {
        try {
            await deleteFunction(`${API_ENDPOINTS.DELETE}?id=${id}`);
            setMessageContent(MESSAGES.DELETE_SUCCESS);
            await fetchCategories();
            setIsMessageModalOpen(true);
        } catch (err) {
            setMessageContent(MESSAGES.DELETE_ERROR);
            setIsMessageModalOpen(true);
        } finally {
            setIsDeleteModalOpen(false);
        }
    };

    return (
        <div className="provinces-container">
            <div className="header-section">
                <h2>الاصناف</h2>
                <button className="add-button" onClick={handleOpenModalForAdd}>
                    اضافه صنف +
                </button>
            </div>

            {loading ? (
                <p>Loading...</p>
            ) : error ? (
                <p className="error-message">{error}</p>
            ) : (
                <table className="provinces-table">
                    <thead>
                        <tr>
                            <th>ID</th>
                            <th>اسم المجموعة</th>
                            <th>الاسم</th>
                            <th>السعر</th>
                            <th>مفعل</th>
                        </tr>
                    </thead>
                    <tbody>
                        {apiData.data && apiData.data.length > 0 ? (
                            apiData.data.map((province, index) => {
                                const groupName = groupNames.find(group => group.id === province.groupId)?.name || "غير محدد";
                                return (
                                    <tr key={province.id} onClick={() => handleOpenModalForEdit(province)}>
                                        <td>{index + 1}</td>
                                        <td>{groupName}</td>
                                        <td>{province.name}</td>
                                        <td>{province.price}</td>
                                        <td>
                                            <div className="checkbox-group TabelCategories">
                                                <input
                                                    type="checkbox"
                                                    name="isEnabled"
                                                    checked={province.isActive}
                                                    onChange={(e) => e.stopPropagation()}
                                                    disabled
                                                />
                                                <label></label>
                                            </div>
                                        </td>
                                    </tr>
                                );
                            })
                        ) : (
                            <tr>
                                <td colSpan="6">لا توجد بيانات متاحة</td>
                            </tr>
                        )}
                    </tbody>
                </table>
            )}
            {isModalOpen && (
                <div className="modal-overlay">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h3>{isEditMode ? 'تعديل صنف' : 'صنف جديد'}</h3>
                            <FontAwesomeIcon
                                icon={faTimes}
                                className="modal-close"
                                onClick={handleCloseModal}
                            />
                        </div>
                        <hr />
                        <div className="modal-body">
                            <div className="form-group">
                                <label>الاسم</label>
                                <input
                                    type="text"
                                    name="name"
                                    value={newProvince.name}
                                    onChange={handleInputChange}
                                    placeholder="اسم الصنف"
                                    required
                                />
                            </div>
                            <div className="form-group">
                                <label>اسم المجموعة</label>
                                <select
                                    name="groupId"
                                    value={newProvince.groupId}
                                    onChange={handleGroupNameChange}
                                    required
                                    style={{ width: "95%" }}
                                >
                                    <option value="">
                                        <em>None</em>
                                    </option>
                                    {groupNames.map((group) => (
                                        <option key={group.id} value={group.id}>
                                            {group.name}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            <div className="form-group">
                                <label>السعر</label>
                                <input
                                    type="number"
                                    name="price"
                                    value={newProvince.price}
                                    onChange={handleInputChange}
                                    placeholder="السعر"
                                />
                            </div>

                            <div className="checkbox-container">
                                <input
                                    type="checkbox"
                                    name="isEnabled"
                                    checked={newProvince.isEnabled}
                                    onChange={handleInputChange}
                                    className='custom-checkbox '
                                />
                                <label>مفعل</label>
                            </div>
                        </div>

                        <div className="modal-footer">
                            <button className="action-button" onClick={handleSave}>
                                <FontAwesomeIcon icon={faSave} /> {isEditMode ? 'حفظ التعديلات' : 'حفظ'}
                            </button>
                            {isEditMode && (
                                <button
                                    className="action-button"
                                    onClick={() => handleDeleteConfirmation(newProvince.id)} // Open delete confirmation modal
                                >
                                    <FontAwesomeIcon icon={faTrash} /> حذف
                                </button>
                            )}
                        </div>
                    </div>
                </div>
            )}

            {/* Delete Confirmation Modal */}
            {isDeleteModalOpen && (
                <div className="modal-overlay">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h3>تأكيد الحذف</h3>
                            <FontAwesomeIcon
                                icon={faTimes}
                                className="modal-close"
                                onClick={() => setIsDeleteModalOpen(false)}
                            />
                        </div>
                        <hr />
                        <div className="modal-body">
                            <p>هل أنت متأكد أنك تريد حذف هذا الصنف؟</p>
                        </div>
                        <div className="modal-footer">
                            <button onClick={() => setIsDeleteModalOpen(false)}>إلغاء</button>
                            <button onClick={() => handleDelete(itemToDelete)}>حذف</button>
                        </div>
                    </div>
                </div>
            )}

            {/* Message Modal */}
            {isMessageModalOpen && (
                <div className="modal-overlay">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h3>رسالة</h3>
                            <FontAwesomeIcon
                                icon={faTimes}
                                className="modal-close"
                                onClick={() => setIsMessageModalOpen(false)}
                            />
                        </div>
                        <hr />
                        <div className="modal-body">
                            <p>{messageContent}</p>
                        </div>
                        <div className="modal-footer">
                            <button onClick={() => setIsMessageModalOpen(false)}>إغلاق</button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}

export default ComponentCategories;

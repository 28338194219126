import { React, useState, useEffect } from "react";
import Sidebar from "../../Component/Sidebar/Sidebar";
import Header from "../../Component/Header/Header";
import { fetchDataWithRetries } from "../../Component/function/FunctionApi"
import TableBills from "./TabelBills/TabelBills"
import TableGovernorates from "./TableGovernorates/TableGovernorates"
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import "./BillingManagement.css"

function BillingManagement() {
  const [data, setData] = useState("")
  const [setStatus] = useState("")
  const [onSearchChange, setOnSearchChange] = useState("")
  const [isAdd, setIsAdd] = useState(false);


  useEffect(() => {
    fetchDataWithRetries("home-sections", setData, setStatus)
  }, [])

  const arrayColum = [{ "id": "كود الفاتورة" }, { "order": "التاريخ" }, { "status": 'العميل' }, { "slug": "العنوان" }, { "Details": "المحافظة" }]

  function createData(id, order, status, slug, Details) {
    return { id, order, status, slug, Details };
  }

  const rows = data && data.data ?
    data.data.map(item => createData(item.id, item.order, item.status, item.slug, "المزيد")) :
    [];

  return (
    <div className="apDiv billingManagement" >
      <Sidebar />
      <div className="body_container container" dir="rtl">
        <div className="Header">
          <Header isSearch="false" title="لوحة التحكم" />
        </div>
        <Tabs className="tabs">
          <TabList>
            <Tab>الفواتير</Tab>
            <Tab>اجمالي المحافظات</Tab>
          </TabList>
          <TabPanel>
            <TableBills/>
          </TabPanel>
          <TabPanel>
            <TableGovernorates/>
          </TabPanel>
        </Tabs>

      </div>
    </div>
  );
}

export default BillingManagement;

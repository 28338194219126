import { React, useState, useEffect } from "react";


function InvoiceDailyLate({ newInvoices }) {

    return (
        <div className="invoice-table-container">
            <div className="scrollable-table">
                <table className="provinces-table">
                    <thead>
                        <tr>
                            <th>التاريخ</th>
                            <th>الحالة</th>
                            <th>العميل</th>
                            <th>المحافظة</th>
                            <th>الملاحظة</th>
                            <th>التليفون</th>
                            <th>العنوان</th>
                            <th>الصفحة</th>
                            <th>الشحن</th>
                            <th>مستعجل</th>
                            <th>لم يتم الرد</th>
                        </tr>
                    </thead>
                    <tbody>
                        {newInvoices.map((invoice, index) => (
                            <tr key={index}>
                                <td>{new Date(invoice.invoiceDate).toLocaleDateString('ar-EG')}</td>
                                <td>{invoice.status}</td>
                                <td>{invoice.customer}</td>
                                <td>{invoice.governorateId}</td>
                                <td>{invoice.note}</td>
                                <td>{invoice.phone}</td>
                                <td>{invoice.address}</td>
                                <td>{invoice.page}</td>
                                <td>{invoice.shipping.toFixed(2)}</td>
                                <td>{invoice.isUrgent ? 'نعم' : 'لا'}</td>
                                <td>{invoice.notReplied ? 'نعم' : 'لا'}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
}

export default InvoiceDailyLate;

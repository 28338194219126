import { React, useState, useEffect } from "react";
import Sidebar from "../../Component/Sidebar/Sidebar";
import Header from "../../Component/Header/Header";
import InvoiceDailyLateCom from "./InvoiceDailyLate";
import { useParams } from "react-router-dom";
import { fetchDataWithRetries } from "../../Component/function/FunctionApi";

function InvoiceDailyLate() {
    const { Name } = useParams(); // Destructure to get Name directly
    const [newInvoices, setNewInvoices] = useState([]);
    const [title, setTitle] = useState(""); // State for the title
    const [apiEndpoint, setApiEndpoint] = useState(""); // State for the API endpoint

    useEffect(() => {
        // Determine the API endpoint and title based on the route parameter
        switch (Name) {
            case "Daily":
                setTitle("فواتير اليوم");
                setApiEndpoint("Invoice/today-new-invoices");
                break;
            case "Late":
                setTitle("فواتير المتأخرة");
                setApiEndpoint("Invoice/late-invoices");
                break;
            case "Urgent":
                setTitle("فواتير المستعجلة");
                setApiEndpoint("Invoice/urgent-invoices");
                break;
            default:
                setTitle("فواتير غير محددة");
                setApiEndpoint("");
                break;
        }
    }, [Name]);

    useEffect(() => {
        if (apiEndpoint) {
            fetchDataWithRetries(apiEndpoint, setNewInvoices);
        }
    }, [apiEndpoint]);

    return (
        <div className="apDiv dashBoard">
            <Sidebar />
            <div className="body_container container" dir="rtl">
                <div className="Header">
                    <Header />
                </div>
                <div className="Bills">
                    <div className="addTabel">
                        <h2 className="table-title">{title}</h2>
                    </div>
                    <InvoiceDailyLateCom newInvoices={newInvoices} />
                </div>
            </div>
        </div>
    );
}

export default InvoiceDailyLate;
